import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import CreateRiskMatrix from "../components/modals/CreateRiskMatrix";
import CreateProjectManagerModal from "../components/modals/CreateProjectManagerModal";
import ProjectManager from "../components/Contracts/ProjectManager";
import RiskManager from "../components/Contracts/RiskManager";
import ClientProjectManaget from "../components/Contracts/ClientProjectManaget";
import ClientRiskManager from "../components/Contracts/ClientRiskManager";
import { useSelector, useDispatch } from "react-redux";
import ShowMatrix from "../components/ShowMatrix";
import { useAddProjectMutation, useProjectNumberQuery } from "../services/apis";
import { PopUp } from "../utils/alert";
import { handleErrors } from "../utils/error";
import Loader from "../utils/loader";
import { setMatrixInfo } from "../services/slices/constants";
import { useNavigate } from "react-router-dom";
import IdGen from "../components/IdGen";
import EmptyMatrix from "../assets/images/eprisk-epmty-matrix.png";

const NewContract = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const matrixInfo = useSelector((state) => state?.constants?.matrixInfo);

  const pmId = useSelector((state) => state?.constants?.pmId);
  const rmId = useSelector((state) => state?.constants?.rmId);
  const clientPmId = useSelector((state) => state?.constants?.clientPmId);
  const clientRmId = useSelector((state) => state?.constants?.clientRmId);

  const [addProject, { data, isLoading, error }] = useAddProjectMutation();

  const [riskMatrix, setRiskMatrix] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [client, setClient] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [projectId, setProjectId] = useState("");
  const [isIdExist, setIsIdExist] = useState(false);

  const {
    data: findId,
    isFetching,
    refetch,
  } = useProjectNumberQuery(projectId);
  const [rangeData, setRangeData] = useState({
    likeStatus: "",
    costImpactStatus: "",
    currency: "",
    likeObject: [],
    costImpact: [],
    colorDesc: [],
    selected: [],
    customCost: false,
    customTypeName: "",
    timeStatus: false,
    timeDropdown: "",
    timeImpact: [],
    customImpact: [],
    matrixInfo: [],
  });

  const showRiskMatrix = () => setRiskMatrix(true);
  const closeRiskMatrix = () => setRiskMatrix(false);

  const [project, setProject] = useState(false);
  const showProject = () => setProject(true);

  useEffect(() => {
    refetch();
  }, [projectId]);

  useEffect(() => {
    if (findId?.data?.length === 0) {
      setIsIdExist(false);
    }

    if (findId?.data?.length > 0) {
      setIsIdExist(true);
    }
  }, [findId]);
  function checkWhiteColor(colors) {
    // Filter out objects with the color 'white'
    const whiteColors =  rangeData?.matrixInfo?.filter(item => item.color === "white");
  
    if (whiteColors.length > 0) {
     return true
    } else {
    return false
    }
  }
  const handleProject = () => {
    if(checkWhiteColor()){
      PopUp("Please fill every cell of matrix.","", "error");
      return;
    }
    const requiredFields = [
      { field: projectName.trim(), message: "Project Name is required" },
      { field: description.trim(), message: "Description is required" },
      { field: id, message: "Contract ID is missing" },
      { field: pmId, message: "Project Manager ID is missing" },
      { field: rmId, message: "Risk Manager ID is missing" },
      { field: clientPmId, message: "Client Project Manager ID is missing" },
      { field: clientRmId, message: "Client Risk Manager ID is missing" },
      { field: startDate, message: "Project start date is required" },
      { field: endDate, message: "Project end date is required" },
    ];

    for (const field of requiredFields) {
      if (!field.field) {
        PopUp(field.message, "", "error");
        return;
      }
    }

    if (new Date(endDate) < new Date(startDate)) {
      PopUp("End date cannot be earlier than the start date", "", "error");
      return;
    }

    if (rangeData?.matrixInfo[0]?.color === "white") {
      PopUp("Please create a matrix", "", "error");
      return;
    }

    if (rangeData?.matrixInfo?.length < 25) {
      PopUp("Please create matrix", "", "error");
      return;
    }

    if (rangeData?.likeObject[0]?.likelihood === "") {
      PopUp("Please create matrix", "", "error");
      return;
    }
    const likeArr = rangeData?.likeObject?.map((item) => ({
      ...item,
      likelihood: String(item["likelihood"]),
    }));

    const costArr = rangeData?.costImpact?.map((item) => ({
      ...item,
      impact: String(item["impact"]),
    }));

    const timeArr = rangeData?.timeImpact?.map((item) => ({
      ...item,
      impact: String(item["impact"]),
    }));

    const customArr = rangeData?.customImpact?.map((item) => ({
      ...item,
      impact: String(item["impact"]),
    }));

    const obj = {
      contractId: id,
      projectName: projectName,
      projectNumber: projectId,
      description: description,
      epPM: pmId,
      epRM: rmId,
      clientPM: clientPmId,
      clientRM: clientRmId,
      client,
      startDate: startDate,
      endDate: endDate,
      scaleTypeLike: rangeData?.likeStatus,
      scaleTypeCostImpact: rangeData?.costImpactStatus,
      scaleTypeTimeImpact: rangeData?.timeStatus,
      costImpactType: rangeData?.currency,
      timeImpactType: rangeData?.timeDropdown,
      isCustomeImpact: JSON.parse(rangeData?.customCost),
      likelihoodDetail: likeArr,
      costImpactDetail: costArr,
      timeImpactDetail: timeArr,
      colorDescription: rangeData?.colorDesc,
      matrix: rangeData?.matrixInfo,
    };

    if (JSON.parse(rangeData?.customCost)) {
      obj.customImpactDetail = customArr;
      obj.customImpactTextDetail = rangeData?.customTypeName;
    }
    addProject(obj);
  };

  useEffect(() => {
    if (data?.success) {
      PopUp("Project created successfully", "", "success");
      dispatch(setMatrixInfo([]));
      navigate(-1);
    }
    handleErrors(error);
  }, [error, data]);

  const handleRange = (data) => {
    setRangeData(data);
  };

  const handleId = (item) => {
    setProjectId(item);
  };
  console.log("matrixInfo", matrixInfo);

  return (
    <>
      <div className="seachBarWIthButton d-flex justify-content-between align-items-center">
        <h6 className="section-heading mb-0">CREATE NEW PROJECT</h6>
      </div>
      <div className="row mt-4">
        <div className="row col-xl-9 mx-0 px-0">
          <div className="row mx-0 px-0">
            <div className="mb-4 col-md-4">
              <label className="cf_label" htmlFor="">
                Project Title
              </label>{" "}
              {/* <span style={{ color: "red" }}>*</span> */}
              <input
                type="text"
                placeholder="Name your project (max 32 chracters)"
                className="input-1 w-100"
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
              />
            </div>
            {/* use field as EP Code */}
            {/* <div className="mb-3 col-md-4">
              <label className="cf_label" htmlFor="">Project Title</label>{" "}
              <input
                type="text"
                placeholder="Name your project (max 32 chracters)"
                className="input-1 w-100"
                value=""
              />
            </div> */}
            <IdGen label="EP Code" handleCall={handleId} status={isIdExist} />

            <div className="mb-4 col-md-4">
              <label className="cf_label" htmlFor="">
                Client
              </label>{" "}
              {/* <span style={{ color: "red" }}>*</span> */}
              <input
                type="text"
                value={client}
                onChange={(e) => setClient(e.target.value)}
                placeholder="Client company or representative"
                className="input-1 w-100"
              />
            </div>
          </div>
          <div className="col-xl-8 row mx-0 px-0">
            <div className="mb-4 col-md-6">
              {/* <label htmlFor="">Choose EP Project Manager</label>{" "}
            <span style={{ color: "red" }}>*</span> */}
              <ProjectManager />
            </div>
            <div className="mb-4 col-md-6">
              {/* <label htmlFor="">Choose EP Risk Manager</label>{" "}
            <span style={{ color: "red" }}>*</span> */}
              <RiskManager />
            </div>
            <div className="mb-4 col-md-6">
              {/* <label htmlFor="">Choose Client Project Manager</label>{" "}
            <span style={{ color: "red" }}>*</span> */}
              <ClientProjectManaget />
            </div>
            <div className="mb-4 col-md-6">
              {/* <label htmlFor="">Choose Client Risk Manager</label>{" "}
            <span style={{ color: "red" }}>*</span> */}
              <ClientRiskManager />
            </div>
            <div className="mb-4 col-md-6">
              <label className="cf_label" htmlFor="">
                Start date
              </label>{" "}
              {/* <span style={{ color: "red" }}>*</span> */}
              <input
                type="date"
                name=""
                id=""
                className="input-1 w-100"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                required
              />
            </div>
            <div className="mb-4 col-md-6">
              <label className="cf_label" htmlFor="">
                End Date
              </label>
              {/* <span style={{ color: "red" }}>*</span> */}
              <input
                type="date"
                min={startDate}
                className="input-1 w-100"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                required
              />
            </div>
            <div className="col-md-12">
              <label className="cf_label" htmlFor="">
                Project Description
              </label>{" "}
              {/* <span style={{ color: "red" }}>*</span> */}
              <textarea
                placeholder="A Brief description of the project (max 250 charaters)"
                rows="4"
                className="textarea-1 w-100"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              >
                Description
              </textarea>
            </div>
          </div>
          <div className="col-xl-4 d-flex flex-column gap-4">
            {rangeData?.matrixInfo?.length > 0 ? (
               <div className="wh-315 ms-auto mt-2">


               <div  onClick={showRiskMatrix} className="position-relative w-100 h-100 p-30 flex1 ps-3">
                 <div className="rotateText position-absolute start-0 m-0 top-0 bottom-0 text-center pt-5">

                   <p
                     className="m-0 top-arrow" style={{ fontSize: '12px',  width: "132px" }} role="button"
                   >
                     Impact
                   </p>
                 </div>
                 
                 <ShowMatrix data={rangeData?.matrixInfo} matrixDesc={rangeData?.colorDesc} onClick={showRiskMatrix} />
                 <div className="position-absolute start-0 end-0 m-0 ps-4 flex1">
                   <p
                     className="text-center m-0 right-arrow" style={{ fontSize: '12px',    width: "132px"}} role="button"
                   >
                     Likelihood
                   </p>

                 </div>
               </div>


         </div>

            ) : (
              <div
                onClick={showRiskMatrix}
                className="emptyMartix position-relative ms-auto"
                role="button"
              >
                <img
                  src={EmptyMatrix}
                  alt="Empty Matrix"
                  className="w-100 h-100 img-fluid"
                />
                <span className="position-absolute top-0 bottom-0 end-0 start-0 m-auto d-inline-block createMatrixEmpty">
                  Click here to calibrate RISK MATRIX
                </span>
              </div>
            )}

            <div className="d-flex flex-column gap-2 mt-5 mb-1 mt-auto w-18 ms-auto">
              <button
                className="btn btn-outline-primary rounded-pill fs-16 px-3 h-48"
                onClick={() => navigate(-1)}
              >
                CANCEL
              </button>
              <button
                className="btn btn-trinary rounded-pill fs-16 px-3 h-48"
                onClick={handleProject}
              >
                {isLoading ? <Loader /> : "SAVE"}
              </button>
            </div>
          </div>
          {/* <div className="col-md-12 text-end">
            <button
              className="btn btn-outline-primary rounded-pill fs-14-normal px-3 h-48"
              onClick={showRiskMatrix}
            >
              Create Risk Matrix
            </button>
          </div> */}
        </div>
        <div className="col-xl-3 mx-0 px-0">
          {/* <h6 className="fs-14-normal text-primary text-end">Base Risk: 9</h6> */}

        </div>
      </div>
      <CreateRiskMatrix
        show={riskMatrix}
        close={closeRiskMatrix}
        rangeData={handleRange}
      />
      <CreateProjectManagerModal show={project} close={showProject} />
    </>
  );
};

export default NewContract;
