import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AssesmentSection from "../components/AssesmentSection";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useParams } from "react-router-dom";
import {
  useGetRiskByDetailQuery,
  useGetProjectDetailQuery,
  useAddAssessmentMutation,
  useGetAssessmentQuery,
} from "../services/apis";
import ShowRiskMatrix from "../components/ShowRiskMatrix";
import { useNavigate } from "react-router-dom";
import { PopUp } from "../utils/alert";
import MitigatedAssessment from "./MitigatedAssessment";
import Loader from "../utils/loader";
import NoData from "../components/NoData";
import Breadcrumbs from "../components/Breadcrumbs";
import { useLocation } from "react-router-dom";
const Assessment = () => {
  const { id, riskId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [addAssessment, { data: assessData, isLoading }] =
    useAddAssessmentMutation();
  const [unmitData, setUnmitData] = useState({
    impact: "",
    liklihood: "",
  });
  const [unmitValues, setUnmitValues] = useState({
    impact: "",
    cost: "",
    time: "",
    quality: "",
  });
  const [color, setColor] = useState("");
  const [tab, setTab] = useState(1);

  const { data, isFetching, refetch } = useGetRiskByDetailQuery(riskId);
  const { data: assessInfo, refetch: assessRefetch } =
    useGetAssessmentQuery(riskId);
  const { data: projectDetail, refetch: projectFetch } =
    useGetProjectDetailQuery(id);
  const currentData =
    assessInfo?.data?.length > 0 &&
    assessInfo?.data?.filter((item) => item?.assessmentType === "Current");
  const unmitigatedData =
    assessInfo?.data?.length > 0 &&
    assessInfo?.data?.filter((item) => item?.assessmentType === "UnMitigated");
  useEffect(() => {
    refetch();
    projectFetch();
    assessRefetch();
  }, [id]);
  useEffect(() => {
    refetch();
  }, [riskId]);
  const handleUnmitData = (data) => {
    setUnmitData(data);
  };
  const handleUnmitValues = (data) => {
    setUnmitValues(data);
  };
  useEffect(() => {
    const matrix = [
      [21, 22, 23, 24, 25],
      [16, 17, 18, 19, 20],
      [11, 12, 13, 14, 15],
      [6, 7, 8, 9, 10],
      [1, 2, 3, 4, 5],
    ];
    function getValueAt(matrix, rowIndex, columnIndex) {
      if (
        rowIndex >= 0 &&
        rowIndex < matrix?.length &&
        columnIndex >= 0 &&
        columnIndex < matrix[rowIndex]?.length
      ) {
        return matrix[rowIndex][columnIndex];
      } else {
        return "Invalid indices";
      }
    }
    const value = getValueAt(
      matrix,
      unmitData?.liklihood - 1,
      unmitData?.impact - 1
    );
    const updatedColor =
      projectDetail?.data?.result[0]?.riskMatrixId?.matrix?.filter(
        (item) => item?.cellId === value
      );
    const colors =
      projectDetail?.data?.result[0]?.riskMatrixId?.colorDescription?.filter(
        (item) => item.color === updatedColor[0]?.color
      );
    setColor(!!colors?.length && colors[0]);
  }, [unmitData]);
  const handleAssessment = (e) => {
    e.preventDefault();
    const maxObject = Object.keys(unmitValues).reduce((maxKey, currentKey) => {
      const currentImpact = parseFloat(unmitValues[currentKey].impact);
      const maxImpact = parseFloat(unmitValues[maxKey].impact);
      return currentImpact > maxImpact ? currentKey : maxKey;
    }, Object.keys(unmitValues)[1]);
    if (!unmitValues?.impact?.description) {
      PopUp("Probabilty score is mandatory", "", "error");
      return;
    }
    const data = projectDetail?.data?.result[0]?.riskMatrixId?.impactScore;

    if (data) {
      data.forEach((item) => {
        const category =
          item.value === "Cost"
            ? "cost"
            : item.value === "Scheduled"
            ? "time"
            : item.value === "Quality"
            ? "quality"
            : null;
        if (unmitValues?.[category]?.description === false) {
          PopUp(`${category} impact is mandatory`, "", "error");
          return;
        }
      });
    }
    const obj = {
      riskId,
      assessmentType: "UnMitigated",
      probabilityName: unmitValues?.impact?.description,
      probabilityValue: unmitValues?.impact?.likelihood,
      consequenceName: unmitValues[maxObject]?.description,
      consequenceValue: unmitValues[maxObject]?.impact,
      riskMatrixValue: {
        xAxis: unmitData?.liklihood,
        yAxis: unmitData?.impact,
        riskValue: color?.description,
        color: color?.color,
      },
    };
    if (unmitValues?.cost?.description !== "") {
      obj.costName = unmitValues?.cost?.description;
      obj.costScore = unmitValues?.cost?.impact;
    }
    if (unmitValues?.time?.description !== "") {
      obj.timeName = unmitValues?.time?.description;
      obj.timeScore = unmitValues?.time?.impact;
    }
    if (unmitValues?.quality?.description !== "") {
      obj.qualityName = unmitValues?.quality?.description;
      obj.qualityScore = unmitValues?.quality?.impact;
    }
    addAssessment(obj);
  };
  useEffect(() => {
    if (assessData?.success) {
      PopUp("Value added successfully", "", "success");
      navigate(-1);
    }
  }, [assessData]);

  console.log("location", assessInfo);

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          {/* <div className="seachBarWIthButton d-flex justify-content-between align-items-center">
            <div>
              <h6 className="heading-sm mb-0 mt-2">Assessment</h6>
            </div>
            <Link
              className="btn btn-outline-primary rounded-pill fs-14-normal px-5 h-48 align-items-center d-flex"
              onClick={() => navigate(-1)}
            >
              Back
            </Link>
          </div> */}
          {/* <div className="idWithInfo mt-3 d-inline-flex">
            <span>
              <small className="text-primary">Risk ID</small>
              {data?.data?.riskNumber}
            </span>
            <span>{data?.data?.riskName}</span>
          </div> */}
          {/* <div className="mt-4 d-flex" style={{ gap: "1em" }}>
            <button
              className={`btn btn-${tab === 1 ? "success" : "primary"}`}
              onClick={() => setTab(1)}
            >
              Unmitigated
            </button>
            <button
              onClick={() => setTab(2)}
              className={`btn btn-${tab === 2 ? "success" : "primary"}`}
            >
              Current
            </button>
            <button
              onClick={() => setTab(3)}
              className={`btn btn-${tab === 3 ? "success" : "primary"}`}
            >
              Mitigated
            </button>
          </div> */}

          {/* {tab === 1 && ( */}

          {/* )} */}
          {/* {tab === 3 && ( */}

          {/* )} */}

          {/* {tab === 2 && ( */}

          {/* )} */}
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="seachBarWIthButton d-flex justify-content-between align-items-center">
            <div>
              <h6 className="section-heading mb-0">Set Your Target Position</h6>
            </div>
            <Link
              className="btn btn-outline-primary rounded-pill fs-16 px-5 h-48 align-items-center d-flex"
              onClick={() => navigate(-1)}
            >
              CANCEL
            </Link>
          </div>

          <div className="row mt-4">
            <div className="col-lg-6 col-12">
              <MitigatedAssessment
                scoreData={
                  projectDetail?.data?.result[0]?.riskMatrixId?.impactScore
                }
              />

              <hr className="bg-black my-5" />
              <div className="row">
                <div className="col-xl-6 d-flex flex-column">
                  <div
                    style={{
                      pointerEvents:
                        data?.data?.isRiskUnMitigated === false
                          ? "auto"
                          : "none",
                    }}
                  >
                    {/* <AssesmentSection
                  title="Unmitigated"
                  info={handleUnmitData}
                  unmitValue={handleUnmitValues}
                  dataValue={assessInfo?.data}
                /> */}
                  </div>

                  <div>
                    <div className="d-flex justify-content-between mb-1 gap-2">
                      <span className="fs-16 text-light-dark">
                        Cost Risk Score (unmitigated)
                      </span>
                      <span className="fs-16 text-light-dark fw-bold">
                        {/* {assessInfo?.data[0]?.consequenceValue} */}
                        {assessInfo?.data
                          .filter(
                            (item) => item.assessmentType === "UnMitigated"
                          )
                          .map((item) => (
                            <p key={item._id}>
                              {item.consequenceValue}
                              {projectDetail?.data?.result[0]?.riskMatrixId
                                ?.costImpactType
                                ? projectDetail?.data?.result[0]?.riskMatrixId
                                    ?.costImpactType == "dollar"
                                  ? "$"
                                  : "%"
                                : ""}
                            </p>
                          ))}
                      </span>
                    </div>

                    <div className="d-flex justify-content-between mb-1 gap-2">
                      <span className="fs-16 text-light-dark">
                        Schedule Risk Score (unmitigated)
                      </span>
                      <span className="fs-16 text-light-dark fw-bold">
                        {/* {assessInfo?.data[0]?.timeScore} */}
                        {assessInfo?.data
                          .filter(
                            (item) => item.assessmentType === "UnMitigated"
                          )
                          .map((item) => (
                            <p key={item._id}>
                              {item.timeScore}
                              {projectDetail?.data?.result[0]?.riskMatrixId
                                ?.costImpactType
                                ? projectDetail?.data?.result[0]?.riskMatrixId
                                    ?.costImpactType == "dollar"
                                  ? "$"
                                  : "%"
                                : ""}
                            </p>
                          ))}
                      </span>
                    </div>
                    {}
                    {projectDetail?.data?.result[0]?.riskMatrixId?.customImpactDetail?.length > 0 && (
                    <div className="d-flex justify-content-between mb-1 gap-2">
                      <span className="fs-16 text-light-dark">
                        {"{"}Health{"}"} Risk Score (unmitigated)
                      </span>
                      <span className="fs-16 text-light-dark fw-bold">
                        {/* {assessInfo?.data[0]?.costPosition} */}
                        {assessInfo?.data
                          .filter(
                            (item) => item.assessmentType === "UnMitigated"
                          )
                          .map((item) => (
                            <p key={item._id}>
                              {item.costPosition}
                              {projectDetail?.data?.result[0]?.riskMatrixId
                                ?.costImpactType
                                ? projectDetail?.data?.result[0]?.riskMatrixId
                                    ?.costImpactType == "dollar"
                                  ? "$"
                                  : "%"
                                : ""}
                            </p>
                          ))}
                      </span>
                    </div>
              )}
                  </div>
                  <div className="wh-17 ms-auto mt-auto">
                    <div className="w-100 mt-5">
                      {/* <h5 className="fs-14-normal text-end mb-0 lh-1">
                            Unmitigated
                          </h5> */}
                      <div>
                        <div className="position-relative w-100 h-100 p-30 flex1">
                          <div className="rotateText position-absolute start-0 m-0 top-0 bottom-0 text-center pt-5">
                            <div className="d-flex me-2 align-items-center justify-content-between">
                              {projectDetail?.data?.result[0]?.riskMatrixId?.timeImpactDetail
                                ?.slice()
                                .reverse()
                                .map((item, index) => (
                                  <OverlayTrigger
                                    placement="left"
                                    overlay={
                                      <Tooltip id="tooltip-disabled">
                                        {item?.description}
                                      </Tooltip>
                                    }
                                  >
                                    <p
                                      key={index}
                                      className="m-0 hoverShow"
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "8px",
                                      }}
                                    >
                                      &nbsp;
                                    </p>
                                  </OverlayTrigger>
                                ))}
                            </div>
                            <p
                              className="m-0 top-arrow"
                              style={{ fontSize: "12px" }}
                              role="button"
                            >
                              Impact
                            </p>
                          </div>
                          <ShowRiskMatrix
                            data={
                              projectDetail?.data?.result[0]?.riskMatrixId
                                ?.matrix
                            }
                            matrixDesc={
                              projectDetail?.data?.result[0]?.riskMatrixId
                                ?.colorDescription
                            }
                            column={
                              unmitData?.impact ||
                              (unmitigatedData.length > 0 &&
                                unmitigatedData[0]?.riskMatrixValue.xAxis)
                            }
                            row={
                              unmitData?.liklihood ||
                              (unmitigatedData?.length > 0 &&
                                unmitigatedData[0]?.riskMatrixValue.yAxis)
                            }
                            width={350}
                          />
                          <div className="position-absolute start-0 end-0 m-0 ps-5 flex1">
                            <p
                              className="text-center m-0 right-arrow"
                              style={{ fontSize: "12px" ,width: 135}}
                              role="button"
                            >
                              Likelihood
                            </p>
                            <div className="d-flex align-items-end justify-content-between">
                              {projectDetail?.data?.result[0]?.riskMatrixId?.likelihoodDetail?.map(
                                (item) => {
                                  return (
                                    <OverlayTrigger
                                      placement="bottom"
                                      overlay={
                                        <Tooltip id="tooltip-disabled">
                                          {item?.description}
                                        </Tooltip>
                                      }
                                    >
                                      <p
                                        className="m-0 hoverShow"
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "8px",
                                        }}
                                      >
                                        &nbsp;
                                      </p>
                                    </OverlayTrigger>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                        {/* <div className="p-30 py-0">
                              <p
                                className="text-center d-flex justify-content-center align-items-center"
                                style={{
                                  marginTop: "1em",
                                  borderRadius: "6px",
                                  padding: "0.5em 1em",
                                  fontSize: "12px",
                                  backgroundColor: "#d9d9d9",
                                  height: "46px",
                                }}
                              >
                                Risk Level:{" "}
                                {color?.description ||
                                  unmitigatedData[0]?.riskMatrixValue?.riskValue}
                              </p>
                            </div> */}
                      </div>
                      <div></div>
                    </div>

                    {data?.data?.isRiskUnMitigated === false && (
                      <Link
                        className="btn btn-primary rounded-pill fs-14-normal px-5 h-48 align-items-center d-flex mt-4"
                        style={{ width: "50%" }}
                        onClick={handleAssessment}
                      >
                        {isLoading ? <Loader /> : "Save"}
                      </Link>
                    )}
                  </div>
                </div>
                <div className="col-xl-6 d-flex flex-column">
                  {currentData[0]?.riskMatrixValue.yAxis !== 0 &&
                  currentData[0]?.riskMatrixValue.yAxis !== undefined ? (
                    <>
                      <div>
                        <div className="d-flex justify-content-between mb-1 gap-2">
                          <span className="fs-16 text-light-dark">
                            Cost Risk Score (current)
                          </span>
                          <span className="fs-16 text-light-dark fw-bold">
                            {/* {assessInfo?.data[2]?.consequenceValue} */}
                            {assessInfo?.data
                              .filter(
                                (item) => item.assessmentType === "Current"
                              )
                              .map((item) => (
                                <p key={item._id}>
                                  {item.consequenceValue}
                                  {projectDetail?.data?.result[0]?.riskMatrixId
                                    ?.costImpactType
                                    ? projectDetail?.data?.result[0]
                                        ?.riskMatrixId?.costImpactType ==
                                      "dollar"
                                      ? "$"
                                      : "%"
                                    : ""}
                                </p>
                              ))}
                          </span>
                        </div>

                        <div className="d-flex justify-content-between mb-1 gap-2">
                          <span className="fs-16 text-light-dark">
                            Schedule Risk Score (current)
                          </span>
                          <span className="fs-16 text-light-dark fw-bold">
                            {/* {assessInfo?.data[2]?.probabilityValue} */}
                            {assessInfo?.data
                              .filter(
                                (item) => item.assessmentType === "Current"
                              )
                              .map((item) => (
                                <p key={item._id}>
                                  {item.probabilityValue}
                                  {projectDetail?.data?.result[0]?.riskMatrixId
                                    ?.costImpactType
                                    ? projectDetail?.data?.result[0]
                                        ?.riskMatrixId?.costImpactType ==
                                      "dollar"
                                      ? "$"
                                      : "%"
                                    : ""}
                                </p>
                              ))}
                          </span>
                        </div>
                        {projectDetail?.data?.result[0]?.riskMatrixId?.customImpactDetail?.length > 0 && (
                          <div className="d-flex justify-content-between mb-1 gap-2">
                            <span className="fs-16 text-light-dark">
                              {"{"}Custom{"}"} Risk Score (current)
                            </span>
                            <span className="fs-16 text-light-dark fw-bold">
                              {/* {assessInfo?.data[2]?.riskAssessmentVersion} */}
                              {assessInfo?.data
                                .filter(
                                  (item) => item.assessmentType === "Current"
                                )
                                .map((item) => (
                                  <p key={item._id}>
                                    {item.riskAssessmentVersion}
                                    {projectDetail?.data?.result[0]?.riskMatrixId
                                      ?.costImpactType
                                      ? projectDetail?.data?.result[0]
                                          ?.riskMatrixId?.costImpactType ==
                                        "dollar"
                                        ? "$"
                                        : "%"
                                      : ""}
                                  </p>
                                ))}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="wh-17 ms-auto mt-auto">
                        <div className="w-100 mt-5">
                          {/* <h5 className="fs-14-normal text-end mb-0 lh-1">
                                  Current
                                </h5> */}
                          <div>
                            <div className="position-relative w-100 h-100 p-30 flex1">
                              <div className="rotateText position-absolute start-0 m-0 top-0 bottom-0 text-center pt-5">
                                <div className="d-flex me-2 align-items-center justify-content-between">
                                  {projectDetail?.data?.result[0]?.riskMatrixId?.timeImpactDetail
                                    ?.slice()
                                    .reverse()
                                    .map((item, index) => (
                                      <OverlayTrigger
                                        placement="left"
                                        overlay={
                                          <Tooltip id="tooltip-disabled">
                                            {item?.description}
                                          </Tooltip>
                                        }
                                      >
                                        <p
                                          key={index}
                                          className="m-0 hoverShow"
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "8px",
                                          }}
                                        >
                                          &nbsp;
                                        </p>
                                      </OverlayTrigger>
                                    ))}
                                </div>
                                <p
                                  className="m-0 top-arrow"
                                  style={{ fontSize: "12px" }}
                                  role="button"
                                >
                                  Impact
                                </p>
                              </div>
                              <ShowRiskMatrix
                                data={
                                  projectDetail?.data?.result[0]?.riskMatrixId
                                    ?.matrix
                                }
                                matrixDesc={
                                  projectDetail?.data?.result[0]?.riskMatrixId
                                    ?.colorDescription
                                }
                                column={currentData[0]?.riskMatrixValue.xAxis}
                                row={currentData[0]?.riskMatrixValue.yAxis}
                           width={500}
                              />
                              <div className="position-absolute start-0 end-0 m-0 ps-5 flex1">
                                <p
                                  className="text-center m-0 right-arrow"
                                  style={{fontSize: 12,
                                  
                                    width: 135}}
                                  role="button"
                                >
                                  Likelihood
                                </p>
                                <div className="d-flex align-items-end justify-content-between">
                                  {projectDetail?.data?.result[0]?.riskMatrixId?.likelihoodDetail?.map(
                                    (item) => {
                                      return (
                                        <OverlayTrigger
                                          placement="bottom"
                                          overlay={
                                            <Tooltip id="tooltip-disabled">
                                              {item?.description}
                                            </Tooltip>
                                          }
                                        >
                                          <p
                                            className="m-0 hoverShow"
                                            style={{
                                              fontWeight: "bold",
                                              fontSize: "8px",
                                            }}
                                          >
                                            &nbsp;
                                          </p>
                                        </OverlayTrigger>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </div>
                            {/* <div className="p-30 py-0">
                              <p
                                className="text-center d-flex justify-content-center align-items-center"
                                style={{
                                  marginTop: "1em",
                                  borderRadius: "6px",
                                  padding: "0.5em 1em",
                                  fontSize: "12px",
                                  backgroundColor: "#d9d9d9",
                                  height: "46px",
                                }}
                              >
                                Risk Level:{" "}
                                {currentData[0]?.riskMatrixValue?.riskValue}
                              </p>
                            </div> */}
                          </div>
                          <div></div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="mt-auto">
                      <NoData text="its depends on UnMitigated and Mitigated status" />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-12">
              <div>
                <h6 className="fs-16 fw-bold mb-1">Risk Description</h6>
                <p className="fs-14 fw-medium text-black lh-base">
                  {location?.state?.data?.description}
                </p>
              </div>
              {location?.state?.data?.mitiData?.map((data) => {
                return (
                  <>
                    <div>
                      <h6 className="fs-16 fw-bold mb-1">
                        {data?.description} {"{"}
                        <span className="text-primary">{data?.actionId}</span>
                        {"}"} :
                      </h6>
                      <p className="fs-14 fw-medium text-black lh-base">
                        {data?.summary}
                      </p>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
          {/* <div className="mt-4 d-flex" style={{ gap: "1em" }}>
            <button
              className={`btn btn-${tab === 1 ? "success" : "primary"}`}
              onClick={() => setTab(1)}
            >
              Unmitigated
            </button>
            <button
              onClick={() => setTab(2)}
              className={`btn btn-${tab === 2 ? "success" : "primary"}`}
            >
              Current
            </button>
            <button
              onClick={() => setTab(3)}
              className={`btn btn-${tab === 3 ? "success" : "primary"}`}
            >
              Mitigated
            </button>
          </div> */}
          {/* {tab === 1 && (
            <div className="row">
              <div
                className="col-md-9"
                style={{
                  pointerEvents:
                    data?.data?.isRiskUnMitigated === false ? "auto" : "none",
                }}
              >
                <AssesmentSection
                  title="Unmitigated"
                  info={handleUnmitData}
                  unmitValue={handleUnmitValues}
                  dataValue={assessInfo?.data}
                />
              </div>
              <div className="col-md-3 mt-5 pt-1">
                <div>

                  <div className="d-flex">
                    <div
                      className="d-flex align-items-center"
                    >
                    </div>
                    <div className="w-100">
                     
                      <div>
                        <div className="position-relative w-100 h-100 p-30 flex1">
                          <div className="rotateText position-absolute start-0 m-0 top-0 bottom-0 text-center pt-5">
                            <div className="d-flex me-2 align-items-center justify-content-between">
                              {projectDetail?.data[0]?.riskMatrixId?.timeImpactDetail
                                ?.slice()
                                .reverse()
                                .map((item, index) => (
                                  <OverlayTrigger placement="left" overlay={<Tooltip id="tooltip-disabled">{item?.description}</Tooltip>}>
                                    <p
                                      key={index}
                                      className="m-0 hoverShow"
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "8px",
                                      }}
                                    >
                                      &nbsp;
                                    </p>
                                  </OverlayTrigger>
                                ))}
                            </div>
                            <p
                              className="m-0 top-arrow" style={{ fontSize: '12px' }} role="button"
                            >
                              Impact
                            </p>
                          </div>
                          <ShowRiskMatrix
                            data={projectDetail?.data[0]?.riskMatrixId?.matrix}
                            column={
                              unmitData?.impact ||
                              (unmitigatedData.length > 0 &&
                                unmitigatedData[0]?.riskMatrixValue.xAxis)
                            }
                            row={
                              unmitData?.liklihood ||
                              (unmitigatedData?.length > 0 &&
                                unmitigatedData[0]?.riskMatrixValue.yAxis)
                            }
                          />
                          <div className="position-absolute start-0 end-0 m-0 ps-5 flex1">
                            <p
                              className="text-center m-0 right-arrow" style={{ fontSize: '12px' }} role="button"
                            >
                              Likelihood
                            </p>
                            <div className="d-flex align-items-end justify-content-between">
                              {projectDetail?.data[0]?.riskMatrixId?.likelihoodDetail?.map(
                                (item) => {
                                  return (
                                    <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled">{item?.description}</Tooltip>}>
                                      <p className="m-0 hoverShow"
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "8px",

                                        }}
                                      >
                                        &nbsp;
                                      </p>
                                    </OverlayTrigger>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="p-30 py-0">
                          <p
                            className="text-center d-flex justify-content-center align-items-center"
                            style={{
                              marginTop: "1em",
                              borderRadius: "6px",
                              padding: "0.5em 1em",
                              fontSize: "12px",
                              backgroundColor: '#d9d9d9',
                              height: '46px',
                            }}
                          >
                            Risk Level:{" "}
                            {color?.description ||
                              unmitigatedData[0]?.riskMatrixValue?.riskValue}

                          </p>
                        </div>
                      </div>
                      <div>



                      </div>

                    </div>
                  </div>

                </div>


                {data?.data?.isRiskUnMitigated === false && (
                  <Link
                    className="btn btn-primary rounded-pill fs-14-normal px-5 h-48 align-items-center d-flex mt-4"
                    style={{ width: "50%" }}
                    onClick={handleAssessment}
                  >
                    {isLoading ? <Loader /> : "Save"}
                  </Link>
                )}
              </div>
            </div>
          )}
          {tab === 2 && (
            <div>
              {currentData[0]?.riskMatrixValue.yAxis !== 0 &&
                currentData[0]?.riskMatrixValue.yAxis !== undefined ? (
                <>
                  <div className="row mx-0 mt-4">
                    <div className="col-md-12 px-0">

                      <div>

                        <div className="d-flex border-style-3 w-25 ps-0">
                          <div className="w-100">

                            <h5 className="fs-14-normal text-end mb-0 lh-1">Current</h5>
                            <div>
                              <div className="position-relative w-100 h-100 p-30 flex1">
                                <div className="rotateText position-absolute start-0 m-0 top-0 bottom-0 text-center pt-5">
                                  <div className="d-flex me-2 align-items-center justify-content-between">
                                    {projectDetail?.data[0]?.riskMatrixId?.timeImpactDetail
                                      ?.slice()
                                      .reverse()
                                      .map((item, index) => (
                                        <OverlayTrigger placement="left" overlay={<Tooltip id="tooltip-disabled">{item?.description}</Tooltip>}>
                                          <p
                                            key={index}
                                            className="m-0 hoverShow"
                                            style={{
                                              fontWeight: "bold",
                                              fontSize: "8px",
                                            }}
                                          >
                                            &nbsp;
                                          </p>
                                        </OverlayTrigger>
                                      ))}
                                  </div>
                                  <p
                                    className="m-0 top-arrow" style={{ fontSize: '12px' }} role="button"
                                  >
                                    Impact
                                  </p>
                                </div>
                                <ShowRiskMatrix
                                  data={projectDetail?.data[0]?.riskMatrixId?.matrix}
                                  column={currentData[0]?.riskMatrixValue.xAxis}
                                  row={currentData[0]?.riskMatrixValue.yAxis}
                                  width={350}
                                />
                                <div className="position-absolute start-0 end-0 m-0 ps-5 flex1">
                                  <p
                                    className="text-center m-0 right-arrow" style={{ fontSize: '12px' }} role="button"
                                  >
                                    Likelihood
                                  </p>
                                  <div className="d-flex align-items-end justify-content-between">
                                    {projectDetail?.data[0]?.riskMatrixId?.likelihoodDetail?.map(
                                      (item) => {
                                        return (
                                          <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled">{item?.description}</Tooltip>}>
                                            <p className="m-0 hoverShow"
                                              style={{
                                                fontWeight: "bold",
                                                fontSize: "8px",

                                              }}
                                            >
                                              &nbsp;
                                            </p>
                                          </OverlayTrigger>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="p-30 py-0">
                                <p
                                  className="text-center d-flex justify-content-center align-items-center"
                                  style={{
                                    marginTop: "1em",
                                    borderRadius: "6px",
                                    padding: "0.5em 1em",
                                    fontSize: "12px",
                                    backgroundColor: '#d9d9d9',
                                    height: '46px',
                                  }}
                                >
                                  Risk Level:{" "}
                                  {currentData[0]?.riskMatrixValue?.riskValue}

                                </p>
                              </div>
                            </div>
                            <div>


                            </div>

                          </div>
                        </div>

                      </div>

                    </div>
                  </div>
                </>
              ) : (
                <NoData text="its depends on UnMitigated and Mitigated status" />
              )}
            </div>
          )}
          {tab === 3 && (
            <MitigatedAssessment
              scoreData={projectDetail?.data[0]?.riskMatrixId?.impactScore}
            />
          )} */}
        </div>
      </div>
    </>
  );
};
export default Assessment;
