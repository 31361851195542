import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { useEffect, useState } from "react";
import Matrix from "../Matrix";
import { useAddMatrixMutation } from "../../services/apis";
import { PopUp } from "../../utils/alert";
import Loader from "../../utils/loader";
import { useDispatch } from "react-redux";
import {
  setImpactValue,
  setLikeValue,
  setMatrixInfo,
  setImpactType,
  setUpdateBox,
} from "../../services/slices/constants";
import {
  initialInputs,
  colorsInput,
  likeValData,
  impactValData,
  costImpactData,
  customImpactData,
  timeImpactData,
  initialInputsEmpty,
  costImpactDataEmpty,
  timeImpactDataEmpty,
  colorsInputEmpty,
} from "../../utils/matrixInitialData";

const toggleStype = {
  textAlign: "left",
  fontSize: "13px",
  display: "flex",
  gap: ".1em",
  background: "#e9e9e9",
  width: "max-content",
  padding: ".1em",
};

function CreateRiskMatrix({ show, close, dataList = undefined, rangeData,disabled }) {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);
  const [inputs, setInputs] = useState(initialInputsEmpty);
  const [inputsTwo, setInputsTwo] = useState(costImpactDataEmpty);
  const [timeImpactItems, setTimeImpactItems] = useState(timeImpactDataEmpty);
  const [customImpactItems, setCustomImpactItems] = useState(customImpactData);
  const [colorArr, setColorArr] = useState(colorsInputEmpty);
  const [customCost, setCustomCost] = useState(false);
  const [selectedColor, setSelectedColor] = useState("green");
  const [inputValues, setInputValues] = useState([]);
  const [likeDataArr, setLikeDataArr] = useState(likeValData);
  const [impactDataArr, setImpactDataArr] = useState(impactValData);
  const [matrixData, setMatrixData] = useState([]);
  const [likeStatus, setLikeStatus] = useState(1);
  const [impactStatus, setImpactStatus] = useState(1);
  const [inputValuesCurrency, setInputValuesCurrency] = useState([]);
  const [currency, setCurrency] = useState("dollar");
  const [customTypeName, setCustomTypeName] = useState("");
  const [timeStatus, setTimeStatus] = useState(1);
  const [timeDropdown, setTimeDropdown] = useState("day");
  const [defaultValue, setDefaultValue] = useState(false);
  const initialMatrixData = Array.from({ length: 25 }, (_, index) => ({
    cellId: index + 1,
    color: "white",
    isFilled: false,
  }));
  const [initialData, setInitialData] = useState(initialMatrixData);

  const [{ isLoading }] = useAddMatrixMutation();

  useEffect(()=>{
    if(defaultValue){
      setInputs(initialInputs)
      setInputsTwo(costImpactData)
      setTimeImpactItems(timeImpactData)
      setColorArr(colorsInput)
    }else{
      setInputs(initialInputsEmpty)
      setInputsTwo(costImpactDataEmpty)
      setTimeImpactItems(timeImpactDataEmpty)
      setColorArr(colorsInputEmpty)
    }
  },[defaultValue])
console.log("matrixData999999==========",matrixData)
  const handleInputChange = (index, key, value) => {
    setInputs((prevInputs) => {
      const newInputs = [...prevInputs];
      newInputs[index] = {
        ...newInputs[index],
        [key]: value,
      };
      return newInputs;
    });
  };


  const rangeHandlers = (index, name, value, state, setState, keyItem) => {
    const newInputs = [...state];
    const updatedInput = { ...newInputs[index] }; // Create a copy of the specific input object
    updatedInput[name] = value;
    if (
      updatedInput["start"] !== "" &&
      !isNaN(updatedInput["start"]) &&
      updatedInput["end"] !== "" &&
      !isNaN(updatedInput["end"])
    ) {
      const start = parseFloat(updatedInput["start"]);
      const end = parseFloat(updatedInput["end"]);
      updatedInput[keyItem] = (start + end) / 2;
    } else {
      updatedInput[keyItem] = "";
    }
    newInputs[index] = updatedInput; // Update the specific input object in the copy
    setState(newInputs);
  };

  const handleLikeRange = (index, name, value) => {
    return rangeHandlers(index, name, value, inputs, setInputs, "likelihood");
  };

  const handleCostImpactRange = (index, name, value) => {
    return rangeHandlers(index, name, value, inputsTwo, setInputsTwo, "impact");
  };

  const handleTimeImpactRange = (index, name, value) => {
    return rangeHandlers(
      index,
      name,
      value,
      timeImpactItems,
      setTimeImpactItems,
      "impact"
    );
  };

  const handleInputChangeImpact = (index, key, value) => {
    const formattedValue = formatValue(value);
    setInputsTwo((prevInputs) => {
      const newInputs = [...prevInputs];
      newInputs[index][key] = formattedValue;
      return newInputs;   
    });
  
    // setTimeImpactItems((prevItems) => {
    //   const newItems = [...prevItems];
    //   newItems[index][key] = formattedValue;
    //   return newItems;
    // });
  };

  const handleInputChangeTwo = (index, key, value) => {
    setTimeImpactItems((prevInputs) => {
      const newInputsTwo = [...prevInputs];
      newInputsTwo[index] = { ...newInputsTwo[index], [key]: value };
      return newInputsTwo;
    });
  };

  const handleCustomInputs = (index, key, value) => {
    setCustomImpactItems((prevInputs) => {
      const newInputsTwo = [...prevInputs];
      newInputsTwo[index] = { ...newInputsTwo[index], [key]: value };
      return newInputsTwo;
    });
  };

  const handleColorSelect = (color) => {
    setSelectedColor(color);
  };

  useEffect(() => {
    if (typeof rangeData === "function") {
      rangeData({
        likeStatus: likeStatus,
        costImpactStatus: impactStatus,
        likeObject: inputs,
        costImpact: inputsTwo,
        currency: currency,
        colorDesc: colorArr,
        selected,
        customCost: customCost,
        customTypeName,
        timeStatus: timeStatus,
        timeDropdown,
        timeImpact: timeImpactItems,
        customImpact: customImpactItems,
        matrixInfo: matrixData,
      });
    }
  }, [
    inputs,
    inputsTwo,
    likeStatus,
    impactStatus,
    colorArr,
    likeDataArr,
    impactDataArr,
    selected,
    customCost,
    customTypeName,
    currency,
    timeImpactItems,
    timeDropdown,
    timeStatus,
    customImpactItems,
    matrixData,
  ]);

  const renderInputs = () => {
    return (
      inputs.length > 0 &&
      inputs.map((item, index) => (
        <div key={index}>
          <div
            className="d-flex"
            style={{ alignItems: "center", marginBottom: "8px", gap: "7px" }}
          >
            <div style={{ fontSize: "13px", fontWeight: "500" }}>
              {index + 1}.{" "}
            </div>
            <input
              type="text"
              style={{ width: "90px", fontSize: "13px" }}
              placeholder="Value"
              disabled={disabled}
              className="singleInput"
              value={defaultValue ? item.likelihood + "%" : item.likelihood} // Display '%' if defaultValue is true
              onChange={(e) => {
                let newValue = e.target.value;
                if (defaultValue && newValue.endsWith("%")) {
                  newValue = newValue.slice(0, -1);
                }
                handleInputChange(index, "likelihood", newValue);
              }}
            />
            <input
              type="text"
              style={{ width: "100%", fontSize: "13px" }}
              placeholder="Description"
              disabled={disabled}
              className="singleInput"
              value={item.description}
              onChange={(e) =>
                handleInputChange(index, "description", e.target.value)
              }
            />
          </div>
        </div>
      ))
    );
  };
  function checkWhiteColor(colors) {
    // Filter out objects with the color 'white'
    const whiteColors = matrixData.filter(item => item.color === "white");
  
    if (whiteColors.length > 0) {
     return true
    } else {
    return false
    }
  }
  const handleMatrix = () => {
    console.log("0000000000=======", checkWhiteColor())
    if(checkWhiteColor()){
      PopUp("Please fill every cell of matrix.","", "error");
      return;
    }
    if (!inputs.every((item) => item.likelihood !== "")) {
      PopUp("Please fill every Value for Level of likelihood", "", "error");
      return;
    }

    if (!inputs.every((item) => item.description !== "")) {
      PopUp(
        "Please fill every description for Level of likelihood",
        "",
        "error"
      );
      return;
    }
 if(impactStatus == 1){

   if (!inputsTwo.every((item) => item.impact !== "")) {
     PopUp("Please fill every Value for Level of impact (cost)", "", "error");
     return;
    }
  }

    if (!inputsTwo.every((item) => item.description !== "")) {
      PopUp(
        "Please fill every description for Level of impact (cost)",
        "",
        "error"
      );
      return;
    }

    if (!timeImpactItems.every((item) => item.impact !== "")) {
      PopUp(
        "Please fill every Value for Level of impact (scheduled)",
        "",
        "error"
      );
      return;
    }

    if (JSON.parse(customCost)) {
      if (customTypeName === "") {
        PopUp("Please Add Custom Cost Score Type", "", "error");
        return;
      }
      if (!customImpactItems.every((item) => item.detail !== "")) {
        PopUp(
          "Please fill every detail for Level of impact (custom)",
          "",
          "error"
        );
        return;
      }
    }

    if (!matrixData.every((item) => item.color !== "white")) {
      PopUp("Please fill every cell of matrix", "", "error");
      return;
    }

    if (!currency) {
      PopUp("Please select currency", "", "error");
      return;
    }

    if (!timeDropdown) {
      PopUp("Please select time duration", "", "error");
      return;
    }

    if (!colorArr.every((item) => item.description !== "")) {
      PopUp("Please fill every description for colors", "", "error");
      return;
    }

    dispatch(setMatrixInfo(matrixData));
    dispatch(setLikeValue(inputValues));
    dispatch(setImpactType(currency));
    dispatch(setImpactValue(inputValuesCurrency));
    PopUp("Matrix created successfully", "", "success");
    dispatch(setUpdateBox(false));

    close();
  };

  const handleMatrixData = (data) => {

    const isAnyCellFilled = data.some(cell => cell.isFilled);
    if(isAnyCellFilled) {
      setMatrixData(data);
    }
    else{
       setMatrixData([]);
       
      }
  };

  useEffect(() => {
    if (dataList !== undefined) {
      const {
        isCustomeImpact,
        customImpactTextDetail,
        scaleTypeLike,
        scaleTypeCostImpact,
        scaleTypeTimeImpact,
        likelihoodDetail,
        costImpactDetail,
        timeImpactDetail,
        customImpactDetail,
        colorDescription,
        costImpactType,
        timeImpactType,
      } = dataList;

      setCustomCost(String(isCustomeImpact));
      setCustomTypeName(customImpactTextDetail);
      setLikeStatus(scaleTypeLike);
      setImpactStatus(scaleTypeCostImpact);
      setTimeStatus(scaleTypeTimeImpact);
      setInputs(likelihoodDetail);
      setInputsTwo(costImpactDetail);
      setTimeImpactItems(timeImpactDetail);
      setCustomImpactItems(customImpactDetail);
      setColorArr(colorDescription);
      setCurrency(costImpactType);
      setTimeDropdown(timeImpactType);
    }
  }, [dataList, initialData]);

  const handleColorDescription = (index, newValue) => {
    const newArray = [...colorArr];
    newArray[index] = { ...newArray[index], description: newValue };
    setColorArr(newArray);
  };

  const handleChangeCurrentColor = (index) => {
    if (index == 0) {
      handleColorSelect("light-green");
    }
    if (index == 1) {
      handleColorSelect("green");
    }
    if (index == 2) {
      handleColorSelect("yellow");
    }
    if (index == 3) {
      handleColorSelect("amber");
    }
    if (index == 4) {
      handleColorSelect("red");
    }
  };

  function formatImpactValue(value) {
    if (!value) return ""; // Return an empty string if value is falsy

    let formattedValue = value.toString(); // Convert value to a string

    // Check if the length of the string is greater than 3
    if (formattedValue.length > 3) {
      // Replace the last 3 characters with 'K'
      formattedValue = formattedValue.slice(0, -3) + "K";
    }

    return formattedValue;
  }

  const handleDefaultValueChange = (e) => {
    const isChecked = e.target.checked;
    setDefaultValue(isChecked);

    if (isChecked) {
      // Set default initial data
      setInitialData([
        { cellId: 1, color: "yellow", isFilled: true, xAxis: 5, yAxis: 1 },
        { cellId: 2, color: "amber", isFilled: true, xAxis: 5, yAxis: 2 },
        { cellId: 3, color: "amber", isFilled: true, xAxis: 5, yAxis: 3 },
        { cellId: 4, color: "red", isFilled: true, xAxis: 5, yAxis: 4 },
        { cellId: 5, color: "red", isFilled: true, xAxis: 5, yAxis: 5 },
        { cellId: 6, color: "yellow", isFilled: true, xAxis: 4, yAxis: 1 },
        { cellId: 7, color: "yellow", isFilled: true, xAxis: 4, yAxis: 2 },
        { cellId: 8, color: "amber", isFilled: true, xAxis: 4, yAxis: 3 },
        { cellId: 9, color: "amber", isFilled: true, xAxis: 4, yAxis: 4 },
        { cellId: 10, color: "red", isFilled: true, xAxis: 4, yAxis: 5 },
        { cellId: 11, color: "green", isFilled: true, xAxis: 3, yAxis: 1 },
        { cellId: 12, color: "yellow", isFilled: true, xAxis: 3, yAxis: 2 },
        { cellId: 13, color: "yellow", isFilled: true, xAxis: 3, yAxis: 3 },
        { cellId: 14, color: "amber", isFilled: true, xAxis: 3, yAxis: 4 },
        { cellId: 15, color: "amber", isFilled: true, xAxis: 3, yAxis: 5 },
        { cellId: 16, color: "light-green", isFilled: true, xAxis: 2, yAxis: 1 },
        { cellId: 17, color: "green", isFilled: true, xAxis: 2, yAxis: 2 },
        { cellId: 18, color: "yellow", isFilled: true, xAxis: 2, yAxis: 3 },
        { cellId: 19, color: "yellow", isFilled: true, xAxis: 2, yAxis: 4 },
        { cellId: 20, color: "yellow", isFilled: true, xAxis: 2, yAxis: 5 },
        { cellId: 21, color: "light-green", isFilled: true, xAxis: 1, yAxis: 1 },
        { cellId: 22, color: "light-green", isFilled: true, xAxis: 1, yAxis: 2 },
        { cellId: 23, color: "green", isFilled: true, xAxis: 1, yAxis: 3 },
        { cellId: 24, color: "yellow", isFilled: true, xAxis: 1, yAxis: 4 },
        { cellId: 25, color: "yellow", isFilled: true, xAxis: 1, yAxis: 5 }
      ]);
    } else {
      // Set initial data to initialMatrixData
      setInitialData(initialMatrixData);
    }
  };
const closeModel =()=>{
  close()
}
  console.log("initiak", initialData)
  const formatValue = (value) => {
    if (value.endsWith("K")) {
      return value.slice(0, -1) + "000";
    }
    return value;
  };
  
  return (
    <>
      <Modal
        show={show}
        dialogClassName="modal-rounded matrixLabel modal-800"
        centered
        onHide={closeModel}
        // onHide={close}
      >
        <Modal.Header
          className="text-center border-0 justify-content-center position-relative abolute-close modal-header flex-column p-0"
      closeButton
        >
          {/* <Modal.Title className="fs-21-600">
            {dataList?.likelihoodDetail === undefined ||
              dataList?.likelihoodDetail?.length === 0
              ? "Create"
              : "Update"}{" "}
            Risk Matrix
          </Modal.Title>
          <h6 className="fs-13 mt-2">Enter data for risk matrix</h6> */}
        </Modal.Header>
        <Modal.Body className="text-center row">
          <div className="row">
            <div className="col-md-7">
              <div className="mb-3" style={{ textAlign: "left" }}>
                <div>
                  <Form.Check
                    className="mb-1 p-0 floatEnd col-5" // prettier-ignore
                    type="switch"
                    id="custom-switch"
                    label="Default Values"
                    value={defaultValue}
                    disabled={disabled}
                    onChange={(e) => handleDefaultValueChange(e)}
                    checked={defaultValue == true ? "checked" : ""}
                  />
                </div>
                <div>
                  {/* <select
                  value={customCost}
                  onChange={(e) => setCustomCost(e.target.value)}
                  style={{
                    width: "80%",
                    padding: ".3em 1em",
                    border: "none",
                    fontSize: "13px",
                  }}
                >
                  <option value={false}>No</option>
                  <option value={true}>Yes</option>
                </select> */}
                  <Form.Check
                    className="mb-1 p-0 floatEnd col-5" // prettier-ignore
                    type="switch"
                    id="custom-switch"
                    label="Custom Score type"
                    value={customCost}
                    disabled={disabled}
                    onChange={(e) => setCustomCost(e.target.checked)}
                    checked={customCost == true ? "checked" : ""}
                  />
                </div>

                {String(customCost) === "true" && (
                  <>
                    <div className="d-flex align-items-end justify-content-end gap-2 mt-2">
                      <label className="mb-2" style={{color:"black", fontWeight: "500"}} htmlFor="">Score type</label>
                      <input
                        type="text"
                        placeholder="Score type"
                        className="singleInput col-md-4"
                        value={customTypeName}
                        disabled={disabled}
                        onChange={(e) => setCustomTypeName(e.target.value)}
                        style={{ fontSize: "13px" }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <div
              className="mb-3"
              style={{ textAlign: "left"}}
            >
              <div>
                {/* <label htmlFor="" className="text-dark text-start">Two Point estimate</label> */}
                {/* <div style={toggleStype} className="rounded-pill">
                  <span className="rounded-circle"
                    style={{
                      background: likeStatus === 1 ? "white" : "",
                      padding: ".5em .5em",
                      color: likeStatus === 1 ? "white" : "black",
                      cursor: "pointer",
                      fontSize: "13px",
                    }}
                    onClick={() => setLikeStatus(1)}
                  >

                  </span>
                  <span className="rounded-circle"
                    style={{
                      background: likeStatus === 2 ? "orange" : "",
                      padding: ".5em .5em",
                      color: likeStatus === 2 ? "white" : "black",
                      cursor: "pointer",
                      fontSize: "13px",
                    }}
                    onClick={() => setLikeStatus(2)}
                  >

                  </span>
                  
                </div> */}
                <Form.Check
                  className="p-0 floatEnd form-check form-switch col-5 text-start text-dark label-dark mb-0" // prettier-ignore
                  type="switch"
                  id="custom-switch"
                  label="Two Point estimate"
                  disabled={disabled}
                  value={likeStatus}
                  onChange={(e) =>
                    likeStatus == 2 ? setLikeStatus(1) : setLikeStatus(2)
                  }
                  checked={likeStatus == 2 ? "checked" : ""}
                  style={{ marginBottom:"-2%" }}
                />
              </div>
              <label htmlFor="" className="w-100 text-start">
                Level of <b className="text-uppercase">likelihood</b>{" "}
                {/* <span style={{ color: "red" }}>*</span> */}
              </label>
              {likeStatus === 1 ? (
                <div className="">{renderInputs()}</div>
              ) : (
                <>
                  {inputs?.map((input, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        gap: "7px",
                        alignItems: "center",
                        marginBottom: ".5em",
                      }}
                    >
                      <div style={{ fontSize: "13px", fontWeight: "500" }}>
                        {index + 1}.{" "}
                      </div>
                      <input
                        type="text"
                        style={{
                          width: "90px",
                          fontSize: "13px",
                        }}
                        placeholder="Minimum"
                        disabled={disabled}
                        className="singleInput"
                        min="0"
                        // value={defaultValue == true ? input.start : ""}
                        // onChange={(e) => {
                        //   handleLikeRange(index, "start", e.target.value);
                        // }}
                        value={defaultValue ? input.start + "%" : input.start} // Display input.end with '%' if defaultValue is true, otherwise display an empty string
                        onChange={(e) => {
                          let newValue = e.target.value;

                          // Remove '%' from the input value only if it's the last character and the new value is not empty
                          if (newValue.endsWith("%")) {
                            newValue = newValue.slice(0, -1); // Remove the last character (percentage sign)
                          }

                          handleLikeRange(index, "start", newValue); // Send the modified input value to handleLikeRange
                        }}
                      />
                      <input
                        type="text"
                        placeholder="Maximum"
                        className="singleInput"
                        disabled={disabled}
                        min="0"
                        style={{
                          width: "90px",
                          fontSize: "13px",
                        }}
                        value={defaultValue ? input.end + "%" : input.end} // Display input.end with '%' if defaultValue is true, otherwise display an empty string
                        onChange={(e) => {
                          let newValue = e.target.value;

                          // Remove '%' from the input value only if it's the last character and the new value is not empty
                          if (newValue.endsWith("%")) {
                            newValue = newValue.slice(0, -1); // Remove the last character (percentage sign)
                          }

                          handleLikeRange(index, "end", newValue); // Send the modified input value to handleLikeRange
                        }}
                      />
                      <input
                        type="text"
                        value={defaultValue == true ? input.description : input.description}
                        placeholder="Description"
                        disabled={disabled}
                        className="singleInput"
                        style={{
                          width: "100%",
                          fontSize: "13px",
                        }}
                        onChange={(e) => {
                          handleLikeRange(index, "description", e.target.value);
                        }}
                      />
                    </div>
                  ))}
                </>
              )}
            </div>

            <div className="mb-2">
              <div class="d-flex align-items-center gap-2" style={{marginBottom:'-20px'}}>
                {/* <label htmlFor="" className="text-dark text-start">Two Point estimate</label>
                <div style={toggleStype} className="rounded-pill">
                  <span className="rounded-circle"
                    style={{
                      background: impactStatus === 1 ? "white" : "",
                      padding: ".5em .5em",
                      color: impactStatus === 1 ? "white" : "black",
                      cursor: "pointer",
                      fontSize: "13px",
                    }}
                    onClick={() => setImpactStatus(1)}
                  >

                  </span>
                  <span className="rounded-circle"
                    style={{
                      background: impactStatus === 2 ? "orange" : "",
                      padding: ".5em .5em",
                      color: impactStatus === 2 ? "white" : "black",
                      cursor: "pointer",
                      fontSize: "13px",
                    }}
                    onClick={() => setImpactStatus(2)}
                  >

                  </span>
                </div> */}
                <Form.Check
                  className="p-0 floatEnd form-check form-switch col-5 text-start text-dark label-dark mb-0" // prettier-ignore
                  type="switch"
                  id="custom-switch"
                  label="Two Point estimate"
                  value={impactStatus}
                  disabled={disabled}
                  onChange={(e) =>
                    impactStatus == 2 ? setImpactStatus(1) : setImpactStatus(2)
                  }
                  checked={impactStatus == 2 ? "checked" : ""}
                />
              </div>

              <label htmlFor="" className="w-100 text-start d-flex justify-content-between align-items-end gap-2 mb-2" style={{marginTop:'-5px'}} >
                <span>Level of{" "}
                <b>
                  <span className="text-uppercase">impact </span>(Cost)
                </b>{" "}</span>
                <div className="d-flex justify-content-end align-items-end gap-2">
                <span style={{ fontSize: "13px", fontWeight: "500", whiteSpace: 'nowrap' }}>
                    Currency Selected:
                  </span>
                  {impactStatus === 1 ? (
                    <select
                    // className="py-2"
                    value={currency}
                    style={{
                      width: "100%",
                      flex:'0 0 50%',
                      // marginRight: "100%",
                   height:"38px",
                      border: "none", 
                      fontSize: "13px",
                      backgroundColor: "#F5F8FB",
                    }}
                    onChange={(e) => setCurrency(e.target.value)}
                  >
                    {/* <option value="percentage">%</option> */}
                    <option value="dollar">$</option>
                    <option value="GBP">£</option>
                    <option value="EUR">€</option>

                  </select>
                  ) : (
                    <select
                    value={currency}
                    style={{
                      width: "100%",
                      flex:'0 0 50%',
                      // marginRight: "100%",
                      // marginTop: ".41em",
                      // padding: ".3em 1em",
                      height:"38px",
                      border: "none",
                      fontSize: "13px",
                      backgroundColor: "#F5F8FB",
                    }}
                    onChange={(e) => setCurrency(e.target.value)}
                  >
                    <option value="percentage">%</option>
                    <option value="dollar">$</option>
                  </select>
                  )}
                </div>
                </label>
                  {/* <span style={{ color: "red" }}> *</span>{" "} */}

                  <div>
                    {" "}
                    {impactStatus === 1 ? (
                      <>
                        
                        {inputsTwo?.map((item, index) => (
                          <>
                            <div
                              className="d-flex"
                              style={{
                                alignItems: "center",
                                marginBottom: "8px",
                                gap: "7px",
                              }}
                            >
                              <div
                                style={{ fontSize: "13px", fontWeight: "500" }}
                              >
                                {index + 1}.{" "}
                              </div>
                              <input
                                key={index}
                                type="text"
                                style={{ width: "90px", fontSize: "13px" }}
                                placeholder="Value"
                                className="singleInput"
                                disabled={disabled}
                                min="0"
                                value={
                                 formatImpactValue(item?.impact)
                                }
                                onChange={(e) => {
                                  const inputValue = e.target.value;

                                  let newValue = inputValue;
                                  if (inputValue.endsWith("K")) {
                                    // Replace "K" with "000"
                                    newValue = inputValue.slice(0, -1) + "000";
                                  }

                                  // Update the input value in the state or parent component
                                  handleInputChangeImpact(
                                    index,
                                    "impact",
                                    newValue
                                  );
                                }}
                              />
                              <input
                                type="text"
                                value={
                                 item.description 
                                }
                                placeholder="Description"
                                disabled={disabled}
                                style={{ width: "100%", fontSize: "13px" }}
                                className="singleInput"
                                onChange={(e) => {
                                  handleInputChangeImpact(
                                    index,
                                    "description",
                                    e.target.value
                                  );
                                  handleInputChangeTwo(
                                    index,
                                    "description",
                                    inputsTwo[index]?.description
                                  );
                                }}
                              />
                            </div>
                          </>
                        ))}
                      </>
                    ) : (
                      <>
                       

                        {inputsTwo?.map((inputTwo, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: ".5em",
                              gap: "7px",
                            }}
                          >
                            <div
                              style={{ fontSize: "13px", fontWeight: "500" }}
                            >
                              {index + 1}.{" "}
                            </div>
                            <input
                              type="text"
                              style={{
                                width: "90px",
                                fontSize: "13px",
                              }}
                              min="0"
                              className="singleInput"
                              placeholder="Minimum"
                              // value={defaultValue == true ? inputTwo.start : ""}
                              // onChange={(e) =>
                              //   handleCostImpactRange(
                              //     index,
                              //     "start",
                              //     e.target.value
                              //   )
                              // }
                              value={
                              formatImpactValue(inputTwo.start)
                                
                              }
                              onChange={(e) => {
                                const inputValue = e.target.value;

                                let newValue = inputValue;
                                if (inputValue.endsWith("K")) {
                                  // Replace "K" with "000"
                                  newValue = inputValue.slice(0, -1) + "000";
                                }

                                // Update the input value in the state or parent component
                                handleInputChangeImpact(
                                  index,
                                  "start",
                                  newValue
                                );
                              }}
                            />
                            <input
                              type="text"
                              // value={defaultValue == true ? inputTwo.end : ""}
                              placeholder="Maximum"
                              className="singleInput"
                              min="0"
                              style={{
                                width: "90px",
                                fontSize: "13px",
                              }}
                              // onChange={(e) =>
                              //   handleCostImpactRange(
                              //     index,
                              //     "end",
                              //     e.target.value
                              //   )
                              // }
                              value={
                               formatImpactValue(inputTwo.end)
                                
                              }
                              onChange={(e) => {
                                const inputValue = formatValue(e.target.value);
                                handleInputChangeImpact(index, "start", inputValue);
                              }}
                            />
                            <input
                              type="text"
                              value={
                               inputTwo.description 
                              }
                              placeholder="Description"
                              disabled={disabled}
                              className="singleInput"
                              style={{
                                width: "100%",
                                fontSize: "13px",
                              }}
                              onChange={(e) => {
                                handleCostImpactRange(
                                  index,
                                  "description",
                                  e.target.value
                                );
                                handleInputChangeTwo(
                                  index,
                                  "description",
                                  inputsTwo[index]?.description
                                );
                              }}
                            />
                          </div>
                        ))}
                      </>
                    )}{" "}
                  </div>


            </div>

            <div className="mb-2 mt-4">
              <div class="d-flex align-items-center gap-2">
                {/* <label htmlFor="" className="text-dark text-start">Two Point estimate</label>
                <div style={toggleStype} className="rounded-pill">
                  <span className="rounded-circle"
                    style={{
                      background: timeStatus === 1 ? "white" : "",
                      padding: ".5em .5em",
                      color: timeStatus === 1 ? "white" : "black",
                      cursor: "pointer",
                      fontSize: "13px",
                    }}
                    onClick={() => setTimeStatus(1)}
                  >

                  </span>
                  <span className="rounded-circle"
                    style={{
                      background: timeStatus === 2 ? "orange" : "",
                      padding: ".5em .5em",
                      color: timeStatus === 2 ? "white" : "black",
                      cursor: "pointer",
                      fontSize: "13px",
                    }}
                    onClick={() => setTimeStatus(2)}
                  >

                  </span>
                </div> */}
                <Form.Check
                  className="p-0 floatEnd form-check form-switch col-5 text-start text-dark label-dark mb-1" // prettier-ignore
                  type="switch"
                  id="custom-switch"
                  label="Two Point estimate"
                  value={timeStatus}
                  disabled={disabled}
                  onChange={(e) =>
                    timeStatus == 2 ? setTimeStatus(1) : setTimeStatus(2)
                  }
                  checked={timeStatus == 2 ? "checked" : ""}
                />
              </div>

              <label
                htmlFor=""
                className="d-block text-start mb-3"
              >
                Level of{" "}
                <b>
                  <span className="text-uppercase">impact </span>(Schedule)
                </b>{" "}
                {/* <span style={{ color: "red" }}>*</span> */}
              </label>
              {timeStatus === 1 ? (
                <>
                  <div
                    className="w-50 ms-auto top-0 mb-2 text-end d-flex align-items-end justify-content-end"
                    style={{
                      position: "relative",
                      marginTop: "-50px",
                      // marginRight: "100%",
                      fontSize: "13px"
                    }}
                  >
                    <span style={{ fontSize: "13px", fontWeight: "500" }}>
                      Time Selected
                    </span>
                    <select
                      className="py-2 px-2 ms-2"
                      value={timeDropdown}
                      style={{
                        border: "none",
                        fontSize: "10px",
                        backgroundColor:"#F5F8FB",
                        width:"40%",
                        height:"38px"
                      }}
                      onChange={(e) => setTimeDropdown(e.target.value)}
                    >
                      <option value="day">Day</option>
                      <option value="week">Week</option>
                      <option value="month">Month</option>
                    </select>
                  </div>

                  {timeImpactItems?.map((item, index) => (
                    <>
                      <div
                        className="d-flex"
                        style={{
                          alignItems: "center",
                          marginBottom: "8px",
                          gap: "7px",
                        }}
                      >
                        <div style={{ fontSize: "13px", fontWeight: "500" }}>
                          {index + 1}.{" "}
                        </div>
                        <input
                          key={index}
                          type="text"
                          style={{ width: "90px", fontSize: "13px" }}
                          placeholder="Value"
                          className="singleInput"
                          min="0"
                          disabled={disabled}
                          value={ item?.impact }
                          onChange={(e) => {
                            handleInputChangeTwo(
                              index,
                              "impact",
                              e.target.value
                            );
                            handleInputChangeTwo(
                              index,
                              "description",
                              inputsTwo[index]?.description
                            );
                          }}
                        />
                        <input
                          type="text"
                          value={ item?.description}
                          disabled
                          placeholder="Description"
                          style={{ width: "100%", fontSize: "12px" }}
                          className="singleInput"
                        />
                      </div>
                    </>
                  ))}
                </>
              ) : (
                <>
                  {/* <select
                    value={timeDropdown}
                    style={{
                      width: "30%",
                      marginRight: "100%",
                      marginBottom: "1em",
                      marginTop: ".41em",
                      padding: ".3em 1em",
                      border: "none",
                    }}
                    onChange={(e) => setTimeDropdown(e.target.value)}
                  >
                    <option value="day">Day</option>
                    <option value="week">Week</option>
                    <option value="month">Month</option>
                  </select> */}
                  <div
                    className="w-50 ms-auto top-0 mb-2 text-end d-flex align-items-end justify-content-end"
                    style={{
                      position: "relative",
                      marginTop: "-50px",
                      // marginRight: "100%",
                    }}
                  >
                    <span style={{ fontSize: "13px",fontWeight: "500" }}>
                      Time Selected
                    </span>
                    <select
                      className="py-2 px-2 ms-2"
                      value={timeDropdown}
                      style={{
                        border: "none",
                        fontSize: "10px",
                        backgroundColor:"#F5F8FB",
                        width:"40%"
                      }}
                      onChange={(e) => setTimeDropdown(e.target.value)}
                    >
                      <option value="day">Day</option>
                      <option value="week">Week</option>
                      <option value="month">Month</option>
                    </select>
                  </div>

                  {timeImpactItems?.map((inputTwo, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        gap: "7px",
                        alignItems: "center",
                        marginBottom: ".5em",
                      }}
                      onClick={() =>
                        handleTimeImpactRange(
                          index,
                          "description",
                          inputsTwo[index]?.description
                        )
                      }
                    >
                      <div style={{ fontSize: "13px", fontWeight: "500" }}>
                        {index + 1}.{" "}
                      </div>
                      <input
                        type="text"
                        style={{
                          width: "90px",
                          fontSize: "13px",
                        }}
                        min="0"
                        className="singleInput"
                        placeholder="Minimum"
                        disabled={disabled}
                        value={inputTwo.start}
                        onChange={(e) =>
                          handleTimeImpactRange(index, "start", e.target.value)
                        }
                      />
                      <input
                        type="text"
                        value={inputTwo.end}
                        placeholder="Maximum"
                        className="singleInput"
                        min="0"
                        disabled={disabled}
                        style={{
                          width: "90px",
                          fontSize: "13px",
                        }}
                        onChange={(e) => {
                          handleTimeImpactRange(index, "end", e.target.value);
                        }}
                      />
                      <input
                        type="text"
                        value={inputTwo.description }
                        disabled
                        placeholder="Description"
                        className="singleInput"
                        style={{
                          width: "100%",
                          fontSize: "13px",
                        }}
                      />
                    </div>
                  ))}
                </>
              )}
            </div>

            {String(customCost) === "true" && (
              <div className="mb-2 mt-4">
                <label htmlFor="" className="w-100 text-start">
                  Value for Level of impact <b>(Custom)</b>{" "}
                  {/* <span style={{ color: "red" }}>*</span> */}
                </label>

                {customImpactItems?.map((item, index) => (
                  <>
                    <div
                      className="d-flex"
                      style={{
                        alignItems: "center",
                        marginBottom: "8px",
                        gap: "7px",
                      }}
                    >
                      <div style={{ fontSize: "13px", fontWeight: "500" }}>
                        {index + 1}.{" "}
                      </div>
                      <input
                        key={index}
                        type="text"
                        style={{ width: "90px", fontSize: "13px" }}
                        placeholder="Value"
                        className="singleInput"
                        value={index + 1}
                        disabled
                      />
                      <input
                        type="text"
                        placeholder="Description"
                        style={{ width: "100%", fontSize: "13px" }}
                        className="singleInput"
                        value={inputsTwo[index]?.description}
                        disabled
                      />

                      <input
                        type="text"
                        value={item.detail}
                        placeholder="Detail"
                        style={{
                          width: "100%",
                          fontSize: "13px",
                        }}
                        disabled={disabled}
                        className="singleInput"
                        onChange={(e) => {
                          handleCustomInputs(index, "detail", e.target.value);
                          handleCustomInputs(
                            index,
                            "description",
                            inputsTwo[index]?.description
                          );
                        }}
                      />
                    </div>
                  </>
                ))}
              </div>
            )}
          </div>
          <div className="col-md-5 col-sm-6 col-12 d-flex flex-column pt-5 ms-auto ps-md-5" style={{marginTop:'-10px'}}>
            <div className="wh-17 mx-auto">
              {/* <label htmlFor="">Fill color</label> */}
              <div className="ps-3 pb-3 position-relative martrix-arrow cr-arrow-top">
                <p
                  className="m-0 ps-3 top-arrow rotateText position-absolute start-0 m-0 top-0 bottom-0 text-center lh-1"
                  style={{ fontSize: "13px" }}
                  role="button"
                >
                  Impact
                </p>
{console.log("selectedColorselectedColor======",selected ,dataList?.matrix,initialData)}
                <Matrix
                  selectedColor={selectedColor}
                  getMatrix={handleMatrixData}
                  dataList={initialData ? initialData : dataList?.matrix}
                  edit={true}
                  initialData={initialData}
                />

                <p
                  className="text-center m-0 right-arrow position-absolute start-0 end-0 m-0 flex1 lh-1 cr-arrow-bottom"
                  style={{ fontSize: "13px" }}
                  role="button"
                >
                  Likelihood
                </p>
              </div>
            </div>

            <div className="mt-4">
              <h6 style={{ fontSize: "13px", display: "flex" }}>
                Select color to fill
              </h6>

              {colorArr?.map((item, index) => {
                return (
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    className="mb-2"
                    key={item?.color}
                  >
                    <div
                      style={{
                        width: "43px",
                        flex:'0 0 43px',
                        height: "43px",
                        marginRight: ".3em",
                        borderRadius: "3px",
                      }}
                      className={`bg-${item.color}`}
                    >
                      {" "}
                    </div>
                    <input
                      type="text"
                      className="singleInput w-100"
                      disabled={disabled}
                      placeholder="Description"
                      style={{ fontSize: "13px" }}
                      value={item?.description}
                      onChange={(e) =>
                        handleColorDescription(index, e.target.value)
                      }
                      onClick={() => handleChangeCurrentColor(index)}
                    />
                  </div>
                );
              })}
            </div>
            {/* <div className="mb-2">
              <label htmlFor="" className="w-100 text-start">
                Select color and fill desired cell
              </label>
              <Dropdown className="mt-2 text-start">
                <Dropdown.Toggle
                  variant="transparent"
                  className="dropWithArrow border position-relative w-50 text-start"
                  id="dropdown-basic"
                >
                  <span
                    className={`px-2 bg-${selectedColor}`}
                    style={{
                      fontSize: "11px",
                      // background: `#e2e2e2`,
                      borderRadius: "10px",
                    }}
                  >
                    {selectedColor}
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu className="w-260 colorScheme">
                  <Dropdown.Item
                    onClick={() => handleColorSelect("light-green")}
                  >
                    <span className="chooseColor me-1 bg-light-green"></span>{" "}
                    Light Green
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleColorSelect("green")}>
                    <span className="chooseColor me-1 bg-green"></span> Green
                  </Dropdown.Item>

                  <Dropdown.Item onClick={() => handleColorSelect("yellow")}>
                    <span className="chooseColor me-1 bg-yellow"></span> Yellow
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleColorSelect("amber")}>
                    <span className="chooseColor me-1 bg-amber"></span> Amber
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleColorSelect("red")}>
                    <span className="chooseColor me-1 bg-red"></span> Red
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div> */}
            <div className="mt-auto mb-2">
              {!disabled &&
              <button
              className="btn btn-trinary rounded-0 h-40 px-5 fs-16 w-100"
              onClick={handleMatrix}
              >
                {isLoading ? <Loader /> : "SAVE"}
              </button>
              }
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-6"></div>
            <div className="col-6"></div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CreateRiskMatrix;
