import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Notification from "../assets/icons/notification.png";
import { Link, useNavigate } from "react-router-dom";
import LogoutModal from "./modals/LogoutModal";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getMessaging, onMessage } from "firebase/messaging";
import { setNotiHead } from "../services/slices/constants";
import Breadcrumbs from "./Breadcrumbs";

const TopHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profileInfo = useSelector(
    (state) => state?.constants?.profileInfo?.data
  );
  const notiTitle = useSelector((state) => state?.constants?.notiHead);
  const [logout, setLogout] = useState(false);
  const location = useLocation();
  const [title, setTitle] = useState("Contracts");

  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });

  useEffect(() => {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      dispatch(setNotiHead(payload?.notification?.title));
      setShow(true);
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
      setTimeout(() => {
        setShow(false);
      }, 5000);
    });
  }, []);

  useEffect(() => {
    if (location.pathname.includes("view-project")) {
      setTitle("Risks");
    } else if (location.pathname.includes("risk")) {
      setTitle("Risks");
    } else if (location.pathname.includes("invitations")) {
      setTitle("Invitations");
    } else if (location.pathname.includes("cms")) {
      setTitle("CMS");
    } else if (location.pathname.includes("support")) {
      setTitle("Platform Support");
    } else if (location.pathname.includes("saved")) {
      setTitle("Saved Reports");
    } else if (location.pathname.includes("saved")) {
      setTitle("Saved Reports");
    } else if (location.pathname.includes("engineers")) {
      setTitle("Engineers");
    } else if (location.pathname.includes("contracts")) {
      setTitle("Contracts");
    } else if (location.pathname.includes("view-contract")) {
      setTitle("Projects");
    } else if (location.pathname.includes("profile")) {
      setTitle("User Profile");
    } else if (location.pathname.includes("add-project")) {
      setTitle("Project");
    } else if (location.pathname.includes("edit-project")) {
      setTitle("Update Project Details");
    } else {
      setTitle("");
    }
  }, [location]);

  const showLogout = () => {
    localStorage.clear();
    setLogout(true);
  };
  const closeLogout = () => setLogout(false);

  return (
    <>
      {show && (
        <div
          style={{
            padding: ".2em",
            background: "green",
            color: "white",
            textAlign: "center",
          }}
        >
          New Notification Received
        </div>
      )}
      <div className="topHeaderWrapper sticky-top d-flex justify-content-between align-items-center">
        <div>
        {(location.pathname.includes("contracts") ||
            location.pathname.includes("platform-support") ||
            location.pathname.includes("invitations") ||
            location.pathname.includes("cms") ||
            location.pathname.includes("view-ticket")) ? <h5 className="title-head mb-0">{title}</h5> : (
            location.pathname.includes("notifications") ? null : <Breadcrumbs />
          )}


        </div>
        <div className="d-flex gap-3 align-items-center">
          <Dropdown>
            <Dropdown.Toggle
              id="dropdown-basic"
              variant="transparent"
              className="p-0 border-0"
            >
              <img
                src={Notification}
                alt="User"
                width="20"
                onClick={() => {
                  dispatch(setNotiHead(""));
                  navigate("/notifications");
                }}
              />
              {notiTitle && (
                <span
                  style={{
                    background: "red",
                    height: "15px",
                    width: "15px",
                    borderRadius: "50%",
                    marginBottom: "20px",
                    border: "2px solid #f9f9fb",
                    marginLeft: "-10px",
                  }}
                ></span>
              )}
            </Dropdown.Toggle>
          </Dropdown>
          <Dropdown>
            <Dropdown.Toggle
              id="dropdown-basic"
              variant="transparent"
              className="p-0 border-0"
            >
              <div className="d-flex gap-2 align-items-center">
                <div className="text-end">
                  <h5 class="mb-0 fs-16 fw-bold lh-1">{profileInfo?.name}</h5>
                  <p className="mb-0 fs-11 text-dark lh-base mt-1">
                    {profileInfo?.email.toLowerCase()}
                  </p>
                </div>
                <div>
                  <div className="nameCircle">
                    {/* {profileInfo?.name.split(" ")[0].charAt(0)}
                    {profileInfo?.name.split(" ")[1].charAt(0)} */}
                  </div>
                </div>

              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Link class="dropdown-item" to="/profile">
                My Profile
              </Link>
              <Link className="dropdown-item text-primary" onClick={showLogout}>
                Logout
              </Link>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <LogoutModal show={logout} close={closeLogout} />
    </>
  );
};

export default TopHeader;
