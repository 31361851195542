import React, { useState, useEffect } from "react";
import Logo from "../assets/images/logo.png";
import InvitationModal from "../components/modals/InvitationModal";
import { useSendInviteMutation } from "../services/apis";
import { PopUp } from "../utils/alert";
import Loader from "../utils/loader";
import { handleErrors } from "../utils/error";
import { validateEmail, validateFullName } from "../utils/constants";

const RequestInvite = () => {
  const [invitation, setInvitation] = useState(false);
const [loading,setLoading]= useState(false)
  const [inviteName, setInviteName] = useState("");
  const [epEmail, setEpEmail] = useState("");
  const [email, setEmail] = useState("");
  const [userRole, setUserRole] = useState("");
  const [userType, setUserType] = useState("");

  const [sendInvite, { data, isLoading, error }] = useSendInviteMutation();

  const handleInvite = () => {
    if (!inviteName.trim()) {
      PopUp("Name is mandatory", "", "error");
      return;
    }
    if (!validateFullName(inviteName)) {
      PopUp("Please enter full name", "", "error");
      return;
    }

    if (!epEmail) {
      PopUp("Project/Risk manager email is mandatory", "", "error");
      return;
    }

    if (!email) {
      PopUp("Email is mandatory", "", "error");
      return;
    }
    if (!validateEmail(epEmail)) {
      PopUp("Please enter a valid email", "", "error");
      return;
    }

    if (!validateEmail(email)) {
      PopUp("Please enter a valid email", "", "error");
      return;
    }

    if (!userRole) {
      PopUp("User role is mandatory", "", "error");
      return;
    }
    if (!userType) {
      PopUp("User type is mandatory", "", "error");
      return;
    }
    setLoading(true)
    sendInvite({
      name: inviteName,
      userRole: Number(userRole),
      userType: Number(userType),
      email,
      pmRmEmail: epEmail,
    });
  };

  useEffect(() => {
    if (data?.success) {
      handleShow();
      setInviteName("");
      setEmail("");
      setEpEmail("");
      setUserRole("");
      setUserType("");
      setLoading(false)
    }
    handleErrors(error);
  }, [data, error]);

  const handleClose = () => setInvitation(false);
  const handleShow = () => setInvitation(true);
  return (
    <>
      <div className="mw-480">
        <div className="authBox bg-white rounded d-flex align-items-center flex-column">
          <div className="authHead w-100">
            <div className="wh-114 mx-auto">
              <img src={Logo} alt="Logo" className="img-contain" />
            </div>
          </div>
          <div className="authBody w-100">
            <h6 class="authTitle text-center mb-3">Request Invite</h6>
            <div className="input-group mb-3">
              <input
                type="text"
                value={inviteName}
                onChange={(e) => setInviteName(e.target.value)}
                class="form-control rounded-pill border"
                placeholder="Your Full Name"
              />
            </div>
            <div className="input-group mb-3">
              <input
                type="email"
                value={epEmail}
                onChange={(e) => setEpEmail(e.target.value)}
                class="form-control rounded-pill border"
                placeholder="Email of Project/Risk manager"
              />
            </div>
            <div className="input-group mb-3">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                class="form-control rounded-pill border"
                placeholder="Your Email"
              />
            </div>

            <div className="input-group mb-3">
              <select
                placeholder="User Role"
                class="form-control rounded-pill border "
                value={userRole}
                onChange={(e) => setUserRole(e.target.value)}
              >
                <option value="">Select user role</option>
                <option value={0}>Project Manager</option>
                <option value={1}>Risk Manager</option>
                <option value={2}>Engineer</option>
              </select>
            </div>

            <div className="input-group mb-3">
              <select
                value={userType}
                onChange={(e) => setUserType(e.target.value)}
                placeholder="User Type"
                class="form-control rounded-pill border"
              >
                <option value="">Select user type</option>
                <option value={0}>Internal</option>
                <option value={1}>External</option>
              </select>
            </div>
            <div className="input-group mt-4">
              <button
                className="btn btn-primary rounded-pill w-100 justify-content-center"
                onClick={()=>loading?null: handleInvite()}
              >
                {isLoading ? <Loader /> : "Send"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <InvitationModal show={invitation} close={handleClose} />
    </>
  );
};

export default RequestInvite;
